import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  ${props => (props.ribbon ? `border-top: solid 4px #bf0000` : null)};
  ${props => (props.rounded ? `border-radius: 0.5rem` : null)};
`;

const Title = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: solid 1px #f1f1f1;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
`;

function ContentCard({ title, ribbon = false, rounded = false, children }) {
  return (
    <Wrapper ribbon={ribbon} rounded={rounded}>
      {title ? <Title>{title}</Title> : null}
      {children}
    </Wrapper>
  );
}

export default ContentCard;
