import React, { useState, useEffect } from "react";
import { Row, Col, Button as BaseButton, Switch as BaseSwitch } from "antd";
import styled from "styled-components";
import _ from "lodash";
import { UpOutlined, DownOutlined, CopyOutlined } from "@ant-design/icons";
import useSWR from "swr";
import ContentCard from "../../../components/ContentCard";
import Loader from "../../../components/Loader";
import Brand from "../../../components/Brand";
import api from "../../../api";
import notification from "../../../common/Notification";

const Button = styled(BaseButton)`
  font-weight: 300 !important;
  height: 40px !important;
  border-radius: 5px;
`;

const Switch = styled(BaseSwitch)`
  border-radius: 2px !important;
  background-color: #828282;
  ant-switch-handle &::after {
    border-radius: 2px !important;
  }
  .ant-switch-handle::before {
    border-radius: 2px !important;
  }
`;

const TokenHolder = styled.div`
  color: #707070;
  background: #f7f9fa;
  border-radius: 0.75rem;
  margin: 0 1rem 1rem 1rem;
  padding: 0.75rem;
`;

const Heading = styled.h3`
  font-size: 1.25rem;
  color: #111;
  font-weight: 300;
  padding: 1rem;
  margin: 0 !important;
`;

const Hr = styled.hr`
  height: auto;
  background: #fff;
  margin: 0 1rem;
  color: #aaa;
  opacity: 0.25;
`;

const CopyLink = styled(Button)`
  text-align: left !important;
  font-weight: 400 !important;
`;

const SubscriptionStatus = styled.div`
  color: ${props => (props.active ? "green" : "red")};
`;

const PreferenceCard = props => {
  const {
    id,
    label,
    subscribed,
    subscribe_label: subscribeActionLabel,
    unsubscribe_label: unsubscribeActionLabel,
    connected,
    connected_text: connectedText,
    icon,
    meta,
    subscriptions = [],
    progressKey,
    onChange
  } = props;
  const [open, setOpen] = useState(false);
  const copyToClipboard = text => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };
  const token = _.chain(meta)
    .find({ id: "token" })
    .value();
  const deepLink = _.chain(meta)
    .find({ id: "deep_link" })
    .value();
  const hasToken = _.get(token, "value", false) && !connected;
  const channelNotConnected = !_.isEmpty(deepLink) && !connected;

  return (
    <Col span={8}>
      <ContentCard>
        <Heading>
          Get notification on <Brand id={id} label={label} showIcon={icon} />
        </Heading>
        {hasToken ? <TokenHolder>{token.value}</TokenHolder> : null}
        {hasToken ? (
          <CopyLink
            type="link"
            onClick={() => {
              copyToClipboard(token.value);
              notification({
                type: "info",
                msg: "Code copied succesfully"
              });
            }}
            style={{ color: "#1364FF" }}
          >
            <CopyOutlined />
            Copy Code
          </CopyLink>
        ) : null}
        <Hr />
        {channelNotConnected ? (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              margin: "12px 16px"
            }}
          >
            <Col style={{ color: "red" }}>Not Activated</Col>
            <Col>
              <Button
                type="primary"
                href={deepLink.value}
                style={{
                  lineHeight: "36px",
                  backgroundColor: "#1364FF",
                  borderRadius: "5px"
                }}
              >
                Open {label} app
              </Button>
            </Col>
          </Row>
        ) : (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              margin: "12px 16px"
            }}
          >
            <Col>
              <SubscriptionStatus active={subscribed}>
                {subscribed ? "Subscribed" : "Unsubscribed"}
              </SubscriptionStatus>
            </Col>
            <Col>
              {connected && subscribed ? (
                <Button
                  type="primary"
                  ghost
                  style={{
                    border: "1px solid #1364FF",
                    borderRadius: "5px",
                    color: "#1364FF"
                  }}
                  loading={progressKey === `${id}.subscription`}
                  onClick={() =>
                    onChange({ id, type: "subscription", value: false })
                  }
                >
                  {unsubscribeActionLabel}
                </Button>
              ) : (
                <Button
                  type="primary"
                  loading={progressKey === `${id}.subscription`}
                  onClick={() =>
                    onChange({ id, type: "subscription", value: true })
                  }
                  style={{ backgroundColor: "#1364FF", borderRadius: "5px" }}
                >
                  {subscribeActionLabel}
                </Button>
              )}
            </Col>
          </Row>
        )}
        {connected && subscribed ? (
          <Row
            style={{
              background: "#F7F7F7"
            }}
          >
            <Col flex="1">
              <BaseButton
                type="link"
                style={{
                  width: "100%",
                  color: "#707070",
                  padding: "1rem",
                  background: "#F7F7F7",
                  display: "flex",
                  alignItems: "center",
                  height: 48
                }}
                onClick={() => setOpen(!open)}
              >
                <span style={{ color: open ? "#707070" : "#000000" }}>
                  Configure app level notification settings
                </span>
                {open ? (
                  <UpOutlined
                    style={{
                      marginLeft: "auto",
                      background: "#707070",
                      padding: 3,
                      borderRadius: 100,
                      color: "#fff",
                      width: 22,
                      height: 22,
                      lineHeight: open ? "18px" : "22px",
                      fontSize: 12
                    }}
                  />
                ) : (
                  <DownOutlined style={{ marginLeft: "auto" }} />
                )}
              </BaseButton>
            </Col>
            {open &&
              subscriptions.map(sub => (
                <Col key={sub.id} span={24}>
                  <Row
                    type="flex"
                    style={{
                      margin: "0 16px",
                      padding: "16px 0",
                      color: "#111",
                      borderTop: "solid 1px #dcdcdc"
                    }}
                  >
                    <Col span={21}>{sub.name}</Col>
                    <Col>
                      <Switch
                        style={{
                          backgroundColor: sub.active ? "#1383f3" : "#828282"
                        }}
                        checked={sub.active}
                        loading={progressKey === `${id}.${sub.id}.subscription`}
                        onChange={value =>
                          onChange({
                            id,
                            appId: sub.id,
                            type: "subscription",
                            value
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        ) : null}
      </ContentCard>
    </Col>
  );
};

function NotificationSettings() {
  const [preferences, setPreferences] = useState(null);
  const [inProgress, setInProgress] = useState("");
  const [error, setError] = useState(null);

  const { data = {} } = useSWR(
    "/getChannelStatus",
    api.notifications.getChannelStatus,
    {
      refreshInterval: 3000
    }
  );

  const fetchPreferences = () =>
    api.notifications
      .getPreferences()
      .then(({ success, data, message: errorMessage }) => {
        if (!success) {
          showError(errorMessage);
          return;
        }
        setPreferences(data);
      })
      .catch(e => {
        showError(e);
      });

  useEffect(() => {
    fetchPreferences();
  }, []);

  useEffect(() => {
    let update = false;
    _.map(data, (app, key) => {
      const prefer = _.find(preferences, { id: key });
      if (_.get(prefer, "connected") !== app) {
        update = true;
      }
    });
    if (update) {
      fetchPreferences();
    }
  }, [data]);

  const showError = data => {
    setError(data);
    setTimeout(() => {
      setError("");
    }, 3000);
  };

  const setProgress = v => {
    setInProgress(
      _.chain(v)
        .values()
        .filter(_.isString)
        .join(".")
        .value()
    );
  };
  const clearProgress = () => {
    setInProgress("");
  };
  const handleChange = value => {
    setProgress(value);
    api.notifications
      .setPreferences(value)
      .then(response => {
        notification({ type: "success", msg: response });
        fetchPreferences();
        clearProgress();
      })
      .catch(e => {
        notification({ type: "error", msg: e });
        clearProgress();
      });
  };

  if (_.isNull(preferences)) {
    return (
      <div style={{ marginTop: "3rem" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      {error ? (
        <div style={{ marginTop: "3rem" }}>
          {notification({ type: "error", msg: error })}
        </div>
      ) : null}
      <Row
        type="flex"
        justify="space-around"
        gutter={32}
        style={{ marginTop: "2rem" }}
      >
        {preferences.map(channelPreference => (
          <PreferenceCard
            key={channelPreference.id}
            onChange={handleChange}
            progressKey={inProgress}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...channelPreference}
          />
        ))}
      </Row>
    </>
  );
}

export default NotificationSettings;
