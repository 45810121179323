import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";
import LandingPage from "./LandingPage";
import RegisterPage from "./RegisterPage";
import SettingsPage from "./SettingsPage";
import LogoutPage from "./LogoutPage";
import history from "../../utils/history";
import { ContentWrapper } from "../../components/Page";
import GlobalStyle from "../../global-styles";

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

function App() {
  return (
    <ContentContainer>
      <ContentWrapper>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/settings" component={SettingsPage} />
            {/* <Route exact path="/admin/add/user" component={UserRegistrationForm} /> */}
            <Route exact path="/admin/add/user" component={RegisterPage} />
            <Route exact path="/logout" component={LogoutPage} />
          </Switch>
        </Router>
      </ContentWrapper>
      <GlobalStyle />
    </ContentContainer>
  );
}
export default App;
