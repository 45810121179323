import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  //  padding: 2rem 0 0 0;
  background: #f0f3f4;
  display: flex;
  // margin-bottom: 3rem;
  margin: 0 1rem;
  .container {
    width: 1280px;
    margin: auto;
  }
`;

function ComponentContainer({ children = [] }) {
  return (
    <Wrapper>
      <div className="container">{children}</div>
    </Wrapper>
  );
}

export default ComponentContainer;
