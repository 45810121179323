import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  margin: auto;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

function Loader({ size, title }) {
  return (
    <Wrapper>
      <Spin
        size={size || "large"}
        style={{ margin: "1rem" }}
        indicator={
          <LoadingOutlined
            type="loading"
            style={{ fontSize: size === "small" ? 15 : 36 }}
            spin
          />
        }
      />
      <h5>{title}</h5>
    </Wrapper>
  );
}

Loader.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string
};

export default Loader;
