/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import OfferingCardRequestButton from "./OfferingCardRequestButton";
import { breakpoint } from "./Page";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1136px;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: block;
  align-items: center;
  text-align: center;
  margin: 3rem 0 2rem;
  line-height: 1.5rem;
  ${breakpoint("md", "xl")`
     margin: 1rem 0 1.5rem; 
     font-size: 1.3rem;
     line-height: 1.6rem;
  `}
  h1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0rem;
    ${breakpoint("xxs", "md")`
      font-size: 1.2rem;    
    `}
  }
  div {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #bd0000 0% 0% no-repeat padding-box;
    text-align: center;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 2rem;
  position: relative;
  width: 18rem;
  height: ${props => (props.access ? "16rem" : "17rem")};
  margin-right: 2.5rem;
  display: inline-block;
  img.big {
    width: 70px;
    height: 72px;
  }
  &:nth-child(3n) {
    @media screen and (min-width: 1440px) {
      margin-right: 0;
    }
  }
  &:nth-last-child(-n + 3) {
    @media screen and (min-width: 1440px) {
      margin-bottom: 0;
    }
  }

  ${breakpoint("xxs", "md")`
     margin-right: 0;
     margin-bottom: 2rem;
     padding: .9rem 1.2rem;
     p {
        height: 94px !important;
        line-height: 15px;
        font-size: 12px;
      }
      img {
         width:54px;
         height:54px;
      }
  `}
  ${breakpoint("md", "lg")`
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  `};
  ${breakpoint("lg", "xxl")`
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-last-child(-n + 3) {
        margin-bottom: 0;
      }
  `};
  ${breakpoint("md", "xl")`
     width: 280px;
     height: 240px;
     margin-right: 2.5rem;
     margin-bottom: 2rem;
     padding: .9rem 1.2rem;
     p {
        height: 70px !important;
        line-height: 17px !important;
        font-size: 14px !important;
      }
      img {
         width:64px;
         height:62px;
      }
  `}
  vertical-align: top;
  background-color: #ffffff;
  ${props => props.access && "box-shadow: 0px 3px 6px #00000029"}
  ${props => !props.access && "border: 1px solid #D3D6D7;"}
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  padding: 1.3rem 1.2rem 0 1.2rem;
  background: ${props => (props.access ? "white" : "#F5F5F5")}
    linear-gradient(
      180deg,
      ${props => (props.color ? props.color : "#0000")} 0%,
      #ffffff00 30%
    )
    0% 0% no-repeat padding-box;
  p {
    letter-spacing: 0px;
    color: #707070;
    height: 70px;
    overflow: auto;
    margin-bottom: ${props => (props.access ? "0.5rem" : "1rem")};
    line-height: 17px;
    font-size: 14px;
  }
  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    font-size: 18px;
    line-height: 22px;
    ${breakpoint("md", "xl")`
      margin-bottom: .8rem;
      font-size: 16px;
      line-height: 22px;
    `}
  }
  .request-access {
    background: #02b700;
    border-radius: 0px 0px 4px 4px;
    color: #ffff;
    box-shadow: 0px 3px 6px #00000029;
    margin: 0 -20px;
    align-content: center;
    display: grid;
    font-size: 13px;
    line-height: 16px;
    height: 28px;
    position: absolute;
    right: 1.2rem;
    left: 1.2rem;
    bottom: 0;
  }
`;

const RequestAccessButton = styled.div`
  color: rgb(19, 79, 243);
  line-height: 15px;
  font-size: 12px;
  cursor: pointer;
  display: inline-flex;
  position: absolute;
  bottom: 6px;
  left: 25%;
  padding: 8px 16px;
`;

const Thumbnail = styled.div`
  margin: 0 0 1.2rem;
  ${breakpoint("md", "xl")`
      margin: 0 0 1.5rem;
  `}
`;

const OfferingCard = ({
  id,
  externalId,
  name,
  displayName,
  path,
  description = "",
  color,
  onClick,
  approved,
  access = true,
  accessRequest,
  showCreateAccessRequest,
  showAccessRequestStatus,
  showUpdateAccessRequest
}) => {
  const imageRef = useRef(null);
  const handleError = () => {
    imageRef.current.width = 72;
    imageRef.current.height = 72;
    imageRef.current.src = `https://tools-cdn.rcprakuten.com/public/assets/images/defaultImage.svg`;
  };
  const onRequestAccess = ({ appId, appName }) => {
    onClick({ externalId, appId, appName, displayName });
  };

  const cardContent = [
    <a
      key="content"
      href={approved ? path : ``}
      className={approved ? "approved" : "disabled"}
    >
      <Thumbnail>
        <img
          ref={imageRef}
          src={`https://tools-cdn.rcprakuten.com/public/assets/logos/${id}.svg`}
          alt={displayName}
          onError={handleError}
          className="big"
        />
      </Thumbnail>
      <h3>{displayName}</h3>
      <p>{description}</p>
    </a>
    // <code style={{ fontSize: "10px" }}>
    //   {JSON.stringify({
    //     name,
    //     accessRequest,
    //     approved,
    //     showCreateAccessRequest,
    //     showAccessRequestStatus,
    //     showUpdateAccessRequest
    //   })}
    // </code>
  ];

  if (showUpdateAccessRequest) {
    cardContent.push(
      <OfferingCardRequestButton
        key="offeringCardRequestButton"
        appId={id}
        appName={name}
        displayName={displayName}
        accessRequest={accessRequest}
        onRequestAccess={onRequestAccess}
      />
    );
  }

  if (showAccessRequestStatus) {
    cardContent.push(
      <div key="request-time" className="request-access">
        Access request sent on {_.get(accessRequest, "lastUpdatedTime")}
      </div>
    );
  }

  if (showCreateAccessRequest) {
    cardContent.push(
      <RequestAccessButton
        key="requested"
        onClick={() => onRequestAccess({ appId: id, appName: name })}
      >
        <img
          src="https://tools-cdn.rcprakuten.com/public/assets/images/lock.svg"
          alt="lock"
          style={{
            width: "10px",
            height: "14px",
            marginRight: ".5rem"
          }}
        />
        Request Access
      </RequestAccessButton>
    );
  }

  return (
    <CardContainer color={color} access={access}>
      {cardContent}
    </CardContainer>
  );
};

OfferingCard.propTypes = {
  id: PropTypes.string.isRequired,
  externalId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  description: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  approved: PropTypes.bool,
  access: PropTypes.bool,
  accessRequest: PropTypes.any,
  showAccessRequestStatus: PropTypes.bool,
  showCreateAccessRequest: PropTypes.bool,
  showUpdateAccessRequest: PropTypes.bool
};

export default OfferingCard;
