import { message } from "antd";
import _ from "lodash";
import {
  APP_SET_LOADER,
  APP_SET_CONFIRMATION_MODAL,
  APP_CLEAR_MESSAGES,
  APP_SUCCESS_MESSAGES,
  APP_ERROR_MESSAGES,
  APP_FETCH_APPS,
  APP_GET_RAKUTEN_USER_DETAILS
} from "./constants";
import adminApi from "./api/admin";
import api from "./api";

export function setConfirmationModal(payload) {
  return dispatch => {
    dispatch({
      type: APP_SET_CONFIRMATION_MODAL,
      data: payload
    });
  };
}

export function setLoader(loading) {
  return dispatch => {
    dispatch({ type: APP_SET_LOADER, data: loading });
  };
}
export function fetchApps() {
  return async dispatch => {
    await dispatch(setLoader(true));
    const { data } = await api.getApps();
    dispatch({
      type: APP_FETCH_APPS,
      data
    });
  };
}

export function fetchRakutenInformation(userEmail) {
  return async dispatch => {
    const { success, data } = await adminApi.getRakutenDetails(userEmail);
    if (success) {
      dispatch({
        type: APP_GET_RAKUTEN_USER_DETAILS,
        data
      });
    } else {
      dispatch({
        type: APP_GET_RAKUTEN_USER_DETAILS,
        data: {
          error: data
        }
      });
      return data;
      // dispatch(errorMessages(data));
    }
  };
}

export function successMessages(_message) {
  return dispatch => {
    dispatch({
      type: APP_SUCCESS_MESSAGES,
      data: {
        type: "success",
        message: _message
      }
    });
  };
}

export function errorMessages(_message) {
  return dispatch => {
    dispatch({
      type: APP_ERROR_MESSAGES,
      data: {
        type: "error",
        message: _message
      }
    });
  };
}

export function clearMessages() {
  message.destroy();
  return dispatch => {
    dispatch({
      type: APP_CLEAR_MESSAGES
    });
  };
}
