/* eslint-disable no-param-reassign */
import _ from "lodash";
import moment from "moment";
import http from "./utils/http";
import aaHttp from "./utils/aaHttp";
import notifications from "./notifications";

async function logOut() {
  try {
    const result = await aaHttp.get("logout");
    return {
      success: result.status === 200
    };
  } catch (e) {
    return {
      success: false
    };
  }
}

export const PartnerAccessStatusTypes = {
  NOT_REQUIRED: "NOT_REQUIRED",
  SOME_APPROVED: "SOME_APPROVED",
  ALL_APPROVED: "ALL_APPROVED",
  CAN_REQUEST: "CAN_REQUEST",
  CAN_REQUEST_MORE: "CAN_REQUEST_MORE",
  PENDING_APPROVAL: "PENDING_APPROVAL"
};

function toPlainAppName(str) {
  // strip space and delimiters
  str = _.replace(str.toLowerCase(), /\s/g, "");
  str = _.replace(str.toLowerCase(), /-/g, "");
  str = _.replace(str.toLowerCase(), /_/g, "");
  return str;
}

async function getPendingRequests() {
  try {
    const pendingRequests = await aaHttp.get(
      "application/authorization/pendingRequests"
    );
    const allApps = await http.get("fetchAllApps");
    if (
      _.get(pendingRequests, "data.status") === "SUCCESS" &&
      _.get(allApps, "data.success")
    ) {
      const groupedByApplicationName = _.chain(pendingRequests)
        .get("data.payload.application_requests", [])
        .reduce((r, c) => {
          const plainName = toPlainAppName(c.applicationName);
          r[plainName] = c;
          return r;
        }, {})
        .value();
      const requests = _.chain(allApps)
        .get("data.data", [])
        .map(item => {
          const plainName = toPlainAppName(item.name);
          const accessRequest = _.get(groupedByApplicationName, plainName);
          if (_.has(groupedByApplicationName, plainName)) {
            try {
              const lastUpdatedTime = _.get(
                accessRequest,
                "last_updated_at",
                false
              );
              accessRequest.lastUpdatedTime = lastUpdatedTime
                ? moment(lastUpdatedTime, "YYYY-MM-DD").format("DD MMM, YYYY")
                : null;
            } catch (error) {
              console.log(error.message);
            }
          }
          return { ...item, accessRequest };
        })
        .value();
      return {
        success: true,
        requests
      };
    }
    return { success: false, allApps: [], requests: [] };
  } catch (e) {
    console.log("API:getRequests", e);
    return { success: false, allApps: [], requests: [] };
  }
}

async function getApps() {
  try {
    const applications = await http.get("apps");
    const applicationRequests = await getPendingRequests();
    const restrictedApplications = [
      "admintool",
      "notifications",
      "rcptools",
      "adminconsole"
    ];
    const displayNames = { crawler: "Shiba RC" };
    const authorizedApplications = _.chain(applications)
      .get("data.data", [])
      .map(({ name }) => toPlainAppName(name))
      .value();
    if (_.get(applications, "data.success")) {
      const { success = false, requests = [] } = applicationRequests;
      if (!success) {
        throw Error("error while fetching requests");
      }
      const result = _.chain(requests)
        .compact()
        .map(item => {
          const plainName = toPlainAppName(item.name);
          const accessRequest = _.get(item, "accessRequest") || {};
          let showUpdateAccessRequest = false;
          let showCreateAccessRequest = false;
          let showAccessRequestStatus = false;
          let approved = false;

          if (_.includes(restrictedApplications, plainName)) {
            return null;
          }

          const externalId = _.get(item, "accessRequest.application_id");

          if (_.isUndefined(externalId)) {
            return null;
          }

          if (_.includes(authorizedApplications, plainName)) {
            approved = true;
          }

          if (!approved) {
            showCreateAccessRequest = true;
            if (accessRequest.count.total > accessRequest.count.pending) {
              showUpdateAccessRequest = true;
            }
            if (accessRequest.count.pending > 0) {
              showCreateAccessRequest = false;
              showAccessRequestStatus = true;
            }
            if (accessRequest.count.pending === 0) {
              showUpdateAccessRequest = false;
            }
          }

          if (approved) {
            if (accessRequest.count.total !== accessRequest.count.approved) {
              showUpdateAccessRequest = true;
            }
            if (accessRequest.count.pending > 0) {
              showAccessRequestStatus = true;
            }
          }

          if (_.get(item, "needApproval") === false) {
            approved = true;
            showAccessRequestStatus = false;
            showCreateAccessRequest = false;
            showUpdateAccessRequest = false;
          }
          const displayName = _.get(displayNames, `${item.id}`, item.name);
          return {
            ...item,
            displayName,
            externalId,
            approved,
            accessRequest,
            showAccessRequestStatus,
            showCreateAccessRequest,
            showUpdateAccessRequest
          };
        })
        .compact()
        .orderBy("name")
        .value();

      return { success: true, data: result };
    }
  } catch (e) {
    console.log(e.message);
  }
  return {
    success: false,
    data: [],
    message: "Error while fetching applications"
  };
}

async function resetPassword(params) {
  try {
    const result = await aaHttp.post(`user/change_password`, params);

    const success = _.get(result, "data.status", "ERROR") === "SUCCESS";
    const message = success
      ? _.get(result, "data.message", "Password updated successfully")
      : _.get(result, "data.error.message", "Something went wrong");

    return {
      success,
      message
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: _.get(error, "data.error.message", "Something went wrong")
    };
  }
}

export default {
  logOut,
  getApps,
  resetPassword,
  notifications
};
