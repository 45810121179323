import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import styled from "styled-components";
import api from "../api";
import notification from "../common/Notification";

const Heading = styled.h3`
  font-size: 1.25rem;
  color: #111;
  font-weight: 300;
  padding: 1rem 4rem 1rem 4rem;
  margin: 0 !important;
`;

// PasswordResetForm.propTypes = {
//   form: PropTypes.object
// };

function PasswordResetForm() {
  const [loading, setLoading] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [form] = Form.useForm();
  const handleSubmit = values => {
    setLoading(true);
    api
      .resetPassword(values)
      .then(response => {
        const { success, message: msg } = response;
        setLoading(false);
        if (success) {
          notification({ type: "success", msg });
          form.resetFields();
        } else {
          notification({ type: "error", msg });
        }
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        notification({ type: "error", msg: "Password reset failed" });
      });
  };
  const {
    getFieldValue,
    validateFields,
    isFieldsTouched,
    getFieldsError
  } = form;

  return (
    <>
      <Heading>Update password</Heading>
      <Form
        name="password_reset"
        form={form}
        onFinish={handleSubmit}
        autoComplete="false"
        style={{
          padding: "1rem 4rem",
          width: "100%"
        }}
      >
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Type your old password" }]}
        >
          <Input.Password size="large" placeholder="Old password" />
        </Form.Item>

        <Form.Item
          name="newPassword"
          hasFeedback
          rules={[
            { required: true, message: "Type your new password" },
            {
              validator: (decorators, value, callback) => {
                if (value && confirmDirty) {
                  validateFields(["retypeNewPassword"], { force: true });
                }
                callback();
              }
            }
          ]}
        >
          <Input.Password size="large" placeholder="New password" />
        </Form.Item>

        <Form.Item
          name="retypeNewPassword"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            { required: true, message: "Type your new password again" },
            {
              validator: (_, value) => {
                if (value && value !== getFieldValue("newPassword")) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    "The two passwords that you entered do not match"
                  );
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input.Password
            size="large"
            placeholder="Re-enter new password"
            onBlur={e => {
              const { value } = e.target;
              setConfirmDirty(confirmDirty || !!value);
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{
                width: "100%"
              }}
              loading={loading}
              disabled={
                !isFieldsTouched(true) ||
                getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              Set Password
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
}

export default PasswordResetForm;
