/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CheckOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import _ from "lodash";
import SelectOption from "./SelectMenuOption";

const Wrapper = styled.div`
  position: absolute;
  min-width: 205px;
  ${props =>
    props.width ? `width: ${props.width}px` : `max-width: ${props.width}px`};
  max-height: ${props => (props.height ? `${props.height}px` : "340px")};
  overflow-y: auto;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  background: #ffff;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;

const SelectTitle = styled.div`
  background: #ffffff;
  width: 200px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  align-content: center;
  border: 1px solid #dbdbdb;
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  padding: 0 1.125rem 0 0.875rem;
  label {
    width: 100%;
  }
`;

function SelectMenu({
  placeholder,
  values = [],
  options = [],
  menuOptions = {},
  onSelect,
  customOption = null,
  customStyle = {}
}) {
  const [visible, setVisible] = useState(false);
  const { width, height } = menuOptions;
  const CustomOption = customOption;
  const menu = (
    <Wrapper width={width} height={height}>
      {_.isEmpty(options) ? (
        <div
          style={{
            padding: "0 1.5rem",
            color: "#b9b9b9"
          }}
        >
          No Data
        </div>
      ) : null}

      {_.chain(options)
        .map(option => {
          return (
            <SelectOption
              role="button"
              key={option.value}
              id={option.value}
              className={`${values.includes(option.value) ? "active" : ""}`}
              onClick={() => onSelect(option.value)}
              style={{
                opacity: option.disabled ? 0.5 : 1,
                pointerEvents: option.disabled ? "none" : "initial",
                cursor: option.disabled ? "not-allowed" : "pointer"
              }}
            >
              <div className="icon" style={{ alignSelf: "center" }}>
                <CheckOutlined />
              </div>
              <div className="title">{option.label}</div>
            </SelectOption>
          );
        })
        .value()}
    </Wrapper>
  );

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const title = !_.isEmpty(values)
    ? `${_.size(values)} partners selected`
    : placeholder;
  return (
    <div role="menu" style={{ position: "relative" }}>
      <Dropdown
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={["click"]}
        placement="bottomLeft"
        getPopupContainer={trigger => trigger.parentElement}
      >
        <SelectTitle
          onClick={e => e.preventDefault()}
          style={!_.isEmpty(customStyle) ? customStyle : {}}
        >
          <label> {title}</label>
          <span>
            <CaretDownOutlined />
          </span>
        </SelectTitle>
      </Dropdown>
    </div>
  );
}

SelectMenu.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  menuOptions: PropTypes.object,
  placeholder: PropTypes.string,
  customStyle: PropTypes.string
};

export default SelectMenu;
