import {
  APP_SHOW_ALERTS,
  APP_CLEAR_ALERTS,
  APP_SET_LOADER,
  APP_SET_CONFIRMATION_MODAL,
  APP_DELETE_USER_SUCCESS,
  APP_DELETE_USER_FAIL,
  APP_CLEAR_MESSAGES,
  APP_SUCCESS_MESSAGES,
  APP_FETCH_APPS,
  APP_ERROR_MESSAGES,
  APP_GET_RAKUTEN_USER_DETAILS
} from "./constants";

export const initialState = {
  loading: true,
  error: false,
  user: false,
  permissions: [],
  alerts: [],
  options: false,
  confirmationModal: {},
  messages: [],
  userDetailsModal: {
    show: false
  },
  rakutenDetails: {
    firstName: "",
    lastName: "",
    error: ""
  },
  appsData: []
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SHOW_ALERTS:
      return { ...state, alerts: [...action.data] };

    case APP_CLEAR_ALERTS:
      return { ...state, alerts: [] };

    case APP_SET_LOADER: {
      return { ...state, loading: action.data };
    }
    case APP_SET_CONFIRMATION_MODAL: {
      return {
        ...state,
        confirmationModal: action.data
      };
    }
    case APP_DELETE_USER_SUCCESS: {
      return {
        ...state,
        messages: action.message
      };
    }
    case APP_DELETE_USER_FAIL: {
      return {
        ...state
      };
    }
    case APP_FETCH_APPS: {
      return {
        ...state,
        appsData: action.data,
        loading: false
      };
    }
    case APP_GET_RAKUTEN_USER_DETAILS: {
      return {
        ...state,
        rakutenDetails: {
          firstName: action.data.first_name,
          lastName: action.data.last_name,
          error: action.data.error
        }
      };
    }
    case APP_CLEAR_MESSAGES: {
      return {
        ...state,
        loading: false,
        messages: []
      };
    }

    case APP_SUCCESS_MESSAGES: {
      return {
        ...state,
        loading: false,
        messages: [action.data]
      };
    }

    case APP_ERROR_MESSAGES: {
      return {
        ...state,
        loading: false,
        messages: [action.data]
      };
    }

    default:
      break;
  }
  return state;
};

export default appReducer;
