import _ from "lodash";
import axios from "axios";
import { clearUser } from "../app-shell/src/utils";

const http = axios.create({
  baseURL: "/service/rcp-tools/notifications/api"
});

http.interceptors.request.use(
  config => {
    try {
      const { token } = JSON.parse(localStorage.getItem("user")) || {};
      config.headers.rcpauth = token || "";
      return config;
    } catch (error) {
      return config;
    }
  },
  err => {
    console.log("error", err);
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      clearUser();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

async function getPreferences() {
  try {
    const response = await http.get("user/preferences");
    return {
      success: _.get(response, "data.success", false),
      data: _.get(response, "data.data", {})
    };
  } catch (error) {
    return {
      success: false,
      message: _.get(error, "message", "Something went wrong")
    };
  }
}

async function setPreferences({ id, appId, type, value }) {
  try {
    const url = _.chain(["user/preferences", id, appId, "subscription"])
      .compact()
      .join("/")
      .value();
    const response = await http[value ? "put" : "delete"](url);
    if (!_.get(response, "data.success", false)) {
      throw response;
    }
    return _.get(response, "data.message", "Success");
  } catch (error) {
    return Promise.reject(
      _.get(error, "data.error.message", "Something went wrong")
    );
  }
}

async function getChannelStatus() {
  try {
    const viber = await http.get(`user/viber/status`);
    const slack = await http.get(`user/slack/status`);
    return {
      viber: _.get(viber, "data.data.active", false),
      slack: _.get(slack, "data.data.active", false)
    };
  } catch (error) {
    return {
      viber: false,
      slack: false
    };
  }
}

export default {
  getPreferences,
  setPreferences,
  getChannelStatus
};
