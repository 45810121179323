/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { useQueryParams, StringParam } from "use-query-params";
import ComponentContainer from "../../../components/ComponentContainer";
import { Spinner } from "../../../common";
import NoResults from "./NoResults";
import { fetchApps } from "../../../actions";
import { breakpoint } from "../../../components/Page";
import RequestAccessModal from "./RequestAccessModal";
import OfferingCard from "../../../components/OfferingCard";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1136px;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: block;
  align-items: center;
  text-align: center;
  margin: 3rem 0 2rem;
  line-height: 1.5rem;
  ${breakpoint("md", "xl")`
     margin: 1rem 0 1.5rem; 
     font-size: 1.3rem;
     line-height: 1.6rem;
  `}
  h1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0rem;
    ${breakpoint("xxs", "md")`
      font-size: 1.2rem;    
    `}
  }
  div {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #bd0000 0% 0% no-repeat padding-box;
    text-align: center;
  }
`;

const Container = styled.div`
  display: inline-block;
  width: calc((3 * 18rem) + 5rem);
  text-align: left;
  ${breakpoint("xxs", "md")`
       width: 100%;
       text-align: center;
    `};
  ${breakpoint("md", "lg")`
       width: calc((2 * 280px) + 2.5rem);
    `};
  ${breakpoint("lg", "xxl")`
       width: calc((3 * 18rem) + 5rem);
    `};
`;

function LandingPage() {
  const [query, setQuery] = useQueryParams({
    appId: StringParam,
    appName: StringParam,
    displayName: StringParam,
    externalId: StringParam
  });
  const loading = useSelector(state => state.global.loading);
  const apps = useSelector(state => {
    let permissions = [];
    try {
      permissions = _.get(
        JSON.parse(localStorage.getItem("user")),
        "permissions",
        []
      );
    } catch (error) {
      console.log(error);
    }

    // eslint-disable-next-line camelcase
    permissions = permissions.map(({ application_name }) => application_name);
    // eslint-disable-next-line no-param-reassign
    return _.chain(state)
      .get("global.appsData")
      .map(app => {
        let enabled = false;
        if (
          _.includes(permissions, _.replace(app.name.toLowerCase(), /\s/g, ""))
        ) {
          enabled = true;
        }
        if (
          _.includes(permissions, _.replace(app.name.toLowerCase(), /\s/g, "-"))
        ) {
          enabled = true;
        }
        return {
          ...app,
          enabled
        };
      })
      .orderBy("name")
      .value();
  });
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const name = _.get(user, "first_name", "");

  useEffect(() => {
    dispatch(fetchApps());
  }, []);

  if (_.isEmpty(user)) {
    return null;
  }

  const showAccessModal = () => {
    setQuery({
      ...query,
      appId: null,
      appName: null,
      externalId: null,
      displayName: null
    });
  };

  const handleOfferingCardClick = updatedQuery => {
    setQuery({
      ...query,
      ...updatedQuery
    });
  };

  if (loading) {
    return <Spinner />;
  }

  const accessReqApp = apps.filter(
    ({ visibleInHome, approved }) => visibleInHome && approved === false
  );

  return (
    <ComponentContainer>
      <Wrapper>
        <Title>
          <h1>Welcome to RCP Console, {name}</h1>
          <div />
        </Title>
        <div style={{ textAlign: "center" }}>
          <Container>
            {apps
              .filter(
                ({ visibleInHome, approved }) =>
                  visibleInHome && approved !== false
              )
              .map(app => (
                <OfferingCard
                  key={app.id}
                  {...app}
                  onClick={handleOfferingCardClick}
                />
              ))}
          </Container>
        </div>
        {!_.isEmpty(accessReqApp) ? (
          <div
            style={{
              textAlign: "center"
            }}
          >
            <Container>
              <div style={{ marginTop: "2.5rem", marginBottom: "1.25rem" }}>
                <span
                  style={{
                    color: "#505050",
                    lineHeight: "22px",
                    fontSize: "15px"
                  }}
                >
                  Apps that require access
                </span>
              </div>
            </Container>
            <Container>
              {accessReqApp.map(app => (
                <OfferingCard
                  key={app.id}
                  {...app}
                  onClick={handleOfferingCardClick}
                  access={false}
                />
              ))}
            </Container>
          </div>
        ) : null}
      </Wrapper>
      {_.isEmpty(apps) && <NoResults />}
      <RequestAccessModal
        onClose={showAccessModal}
        visible={!_.isEmpty(query.appId)}
      />
    </ComponentContainer>
  );
}

LandingPage.propTypes = {
  appsData: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

export default LandingPage;
