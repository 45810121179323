import styled from "styled-components";
import { createBreakpoint } from "styled-components-breakpoint";

export const Page = styled.main`
  display: flex;
  width: 100%;
`;

export const PageTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #032d98;
  margin-right: 2rem;
`;

export const Separator = styled.span`
  width: 1px;
  background: #d8d8d8;
  margin: 0 0.75rem 0 1rem;
  height: 2rem;
`;

export const SearchSeparator = styled.div`
  margin: 1rem -1rem;
  height: 1px;
  border-top: 1px solid #e2e2e2;
`;

export const Sidebar = styled.nav`
  width: 60px;
  position: relative;
  z-index: 5;
  display: flex;
  flex: 0 0 auto;
`;

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 2rem auto;
  min-height: 85vh;
`;

export const Panel = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  margin-bottom: ${props => (props.search ? "20px" : "0")}
  border-top: ${props => (props.ribbon ? "solid 0.25rem #032d98" : "none")};
  display: ${props => props.type || "initial"};
  align-items: ${props => props.align || "initial"};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const breakpoint = createBreakpoint({
  xxs: 320,
  xs: 375,
  sm: 414,
  md: 758,
  lg: 1034,
  xl: 1290,
  xxl: 1450
});
