import * as yup from "yup";
import { isEmpty, isUndefined, first } from "lodash";
import adminApi from "../api/admin";
import { getQueryParams, rakuteDomainName } from "../utils/common.utils";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const queryParameters = getQueryParams(window.location.href);

let message = "";
/**
 *
 * Schema to validate register user form
 */
export const CreateUser = yup.object().shape({
  userEmail: yup
    .string()
    .nullable()
    .trim()
    .email("app.user.label.valid_email_is_required")
    .test({
      name: "uniqueEmail",
      test: async function(value) {
        if (
          isUndefined(queryParameters.email) &&
          !isUndefined(value) &&
          !isEmpty(value)
        ) {
          try {
            const response = await adminApi.getUserEmailId(value);
            if (response.success) {
              if (!isEmpty(response.data)) {
                const firstName =
                  !isUndefined(first(response.data).firstName) &&
                  first(response.data).firstName;
                const lastName =
                  !isUndefined(first(response.data).lastName) &&
                  first(response.data).lastName;
                message = `app.user.label.email_is_taken?firstName=${firstName}&lastName=${lastName}`;
                return this.createError({
                  message,
                  path: "userEmail"
                });
              } else {
                return true;
              }
            } else {
              return this.createError({
                message: "app.user.label.error_message",
                path: "userEmail"
              });
            }
          } catch {
            return this.createError({
              message: "app.user.label.error_message",
              path: "userEmail"
            });
          }
        } else {
          return true;
        }
      }
    })
    .required("app.user.label.email_is_required"),
  userFirstName: yup
    .string()
    .max(30, "app.user.label.user_name_is_long")
    .trim()
    .required("app.user.label.user_first_name_is_required"),
  userLastName: yup
    .string()
    .max(30, "app.user.label.user_name_is_long")
    .trim()
    .required("app.user.label.user_last_name_is_required"),
  userPassword: yup
    .string()
    .min(8, "app.user.label.min_password_length")
    .max(50, "app.user.label.max_password_length")
    .when("$value", (value, passSchema, parent) =>
      !isUndefined(parent.parent.userEmail) &&
      parent.parent.userEmail.includes(rakuteDomainName)
        ? passSchema.notRequired()
        : passSchema.required("app.user.label.password_is_required")
    ),
  userConfirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("userPassword"), null],
      "app.user.label.password_dont_match"
    )
    .when("$value", (value, passSchema, parent) =>
      !isUndefined(parent.parent.userEmail) &&
      parent.parent.userEmail.includes(rakuteDomainName)
        ? passSchema.notRequired()
        : passSchema.required("app.user.label.confirm_password_is_required")
    )
});
