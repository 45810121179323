import React, { useState, useEffect, memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { Form, Row, Input, Button, Col } from "antd";
import { Formik, ErrorMessage } from "formik";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { isUndefined, size, isEmpty } from "lodash";
import { useQueryParams, StringParam } from "use-query-params";
import { CreateUser } from "../../../common/validators";
import { rakuteDomainName } from "../../../utils/common.utils";
import { ContentWrapper, breakpoint } from "../../../components/Page";
import GlobalStyle from "../../../global-styles";
import Loader from "../../../components/Loader";
import adminApi from "../../../api/admin";
import { fetchRakutenInformation } from "../../../actions";
import ContentCard from "../../../components/ContentCard";
import notification from "../../../common/Notification";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 40px !important;
  width: 100%;
  .ant-form-item-label {
    text-align: left;
    padding-left: 3px;
    label {
      font-size: 16px !important;
      color: #505050 !important;
      font-weight: 300;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 575px) {
    .ant-col-sm-0 {
      max-width: 0% !important;
    }
  }
  &.hide {
    .ant-form-item-label {
      display: none;
    }
  }
  ${breakpoint("xxs", "sm")`
    &.hide {
      .ant-form-item-label {
        display: none;
      }
    }
  `};
  ${breakpoint("sm", "lg")`
    &.hide {
      .ant-form-item-label {
        display: inline-block;
        opacity: 0;
      }
    }
  `};
  @media screen and (min-width: 992px) and (max-width: 1033px) {
    &.hide {
      .ant-form-item-label {
        display: none;
      }
    }
  }
`;
const InfoLabel = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 11 },
    md: { span: 11 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 12 }
  }
};

function UserRegistrationForm({ dispatch, rakutenDetails }) {
  const { formatMessage: f } = useIntl();
  const submitButtonId = `user-save`;
  const cancelButtonId = `user-close-add-modal`;
  const [mounted, setMounted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rakutenName, setRakutenDetails] = useState({
    firstName: "",
    lastName: "",
    error: ""
  });
  const [queryParameters] = useQueryParams({
    referer: StringParam,
    email: StringParam
  });
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    userPassword: "",
    userConfirmPassword: ""
  });

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    if (
      mounted &&
      !isUndefined(queryParameters.email) &&
      queryParameters.email.includes(rakuteDomainName)
    ) {
      getRakutenUserDetails();
    }
  }, [mounted]);
  useEffect(() => {
    setRakutenDetails({
      firstName: rakutenDetails.firstName,
      lastName: rakutenDetails.lastName,
      error: rakutenDetails.error
    });
  }, [rakutenDetails]);
  const isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    queryParameters.email
  );
  /**
   *
   * @param {object} values - The form submited user info
   */

  const handleRegister = async values => {
    setUser({
      firstName: values.userFirstName,
      lastName: values.userLastName,
      userPassword: values.userPassword,
      userConfirmPassword: values.userPassword
    });
    const registerEmail = await adminApi.registerNewUser({
      first_name: values.userFirstName,
      last_name: values.userLastName,
      email: values.userEmail,
      password: values.userEmail.includes(rakuteDomainName)
        ? undefined
        : values.userPassword
    });
    setLoading(true);
    try {
      if (registerEmail.success) {
        const { email } = registerEmail.data;
        const referrer = window.location.href.split("referer=")[1];
        if (!isUndefined(referrer)) {
          window.location.replace(`${referrer}&email=${email}`);
        }
        notification({ type: "success", msg: "User registered successfully" });
      } else {
        notification({ type: "error", msg: registerEmail.data });
      }
    } catch (e) {
      console.log(e);
      notification({ type: "error", msg: "Something went wrong" });
    }
    setLoading(false);
  };

  /**
   *
   * Cancels the registeration process and goes back to referer url
   */
  const handleCancel = () => {
    const referrer = window.location.href.split("referer=")[1];
    if (isUndefined(referrer)) {
      window.location.replace("/rcp-tools");
      return;
    }
    window.location.replace(`${referrer.split("?")[0]}`);
  };

  /**
   * Fetch Rakuten details
   */
  const getRakutenUserDetails = async () => {
    setLoading(true);
    const repsonse = await dispatch(
      fetchRakutenInformation(queryParameters.email)
    );
    if (repsonse) {
      notification({ type: "error", msg: repsonse });
    }
    setLoading(false);
  };

  const FormContainer = ({ props }) => {
    const { getFieldProps, errors, touched, values } = props;
    return (
      <div key="formContent">
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formItemLayout}
        >
          <div style={{ padding: "30px 42px 10px 42px" }}>
            <Row flexDirection="ltr" gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}>
              <Col xs={24} lg={12}>
                {isUndefined(queryParameters.email) ? (
                  <InfoLabel className="error-text">Email is absent</InfoLabel>
                ) : (
                  <FormItem
                    label={f({
                      id: "app.user.modal.email_address"
                    })}
                    // required={isUndefined(queryParameters.email)}
                    colon={false}
                    key="form-email"
                  >
                    {isUndefined(queryParameters.email) ? null : (
                      <Input
                        size="large"
                        value={queryParameters.email}
                        disabled
                      />
                    )}
                    {!isUndefined(queryParameters.email) && !isEmailValid && (
                      <div className="error-text">
                        {f({
                          id: "app.user.modal.invalid_email"
                        })}
                      </div>
                    )}
                  </FormItem>
                )}
              </Col>
            </Row>
            {!isUndefined(queryParameters.email) && !isEmailValid ? null : (
              <>
                <Row
                  flexDirection="ltr"
                  gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}
                >
                  <Col xs={24} lg={12}>
                    {(!isUndefined(queryParameters.email) ||
                      (touched.userEmail &&
                        isUndefined(errors.userEmail) &&
                        !isUndefined(values.userEmail) &&
                        Boolean(size(values.userEmail)))) && (
                      <FormItem
                        label={f({
                          id: "app.user.modal.name"
                        })}
                        colon={false}
                        key="form-fName"
                      >
                        {size(rakutenName.firstName) ? (
                          <Input
                            size="large"
                            key="first_name"
                            value={rakutenName.firstName}
                            disabled
                          />
                        ) : (
                          <>
                            <Input
                              size="large"
                              key="first_name"
                              className={
                                touched.userFirstName &&
                                errors.userFirstName &&
                                "input-error"
                              }
                              placeholder={f({
                                id: "app.user.modal.enter_first_name"
                              })}
                              disabled={
                                !isUndefined(rakutenName.error) &&
                                Boolean(size(rakutenName.error))
                              }
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...getFieldProps("userFirstName")}
                            />
                            <ErrorMessage
                              name="userFirstName"
                              render={msg => (
                                <div className="error-text">
                                  {f({
                                    id: msg
                                  })}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </FormItem>
                    )}
                  </Col>
                  <Col xs={24} lg={12}>
                    {(!isUndefined(queryParameters.email) ||
                      (touched.userEmail &&
                        isUndefined(errors.userEmail) &&
                        !isUndefined(values.userEmail) &&
                        Boolean(size(values.userEmail)))) && (
                      <FormItem
                        colon={false}
                        key="form-lName"
                        label="1"
                        className="hide"
                      >
                        {size(rakutenName.lastName) ? (
                          <Input
                            size="large"
                            key="last_name"
                            value={rakutenName.lastName}
                            disabled
                          />
                        ) : (
                          <>
                            <Input
                              size="large"
                              key="last_name"
                              placeholder={f({
                                id: "app.user.modal.enter_last_name"
                              })}
                              className={
                                touched.userLastName &&
                                errors.userLastName &&
                                "input-error"
                              }
                              disabled={
                                !isUndefined(rakutenName.error) &&
                                Boolean(size(rakutenName.error))
                              }
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...getFieldProps("userLastName")}
                            />
                            <ErrorMessage
                              name="userLastName"
                              render={msg => (
                                <div className="error-text">
                                  {f({
                                    id: msg
                                  })}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
                <Row
                  flexDirection="ltr"
                  gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}
                >
                  <Col xs={24} lg={12}>
                    {(!isUndefined(queryParameters.email) ||
                      (touched.userEmail &&
                        isUndefined(errors.userEmail) &&
                        !isUndefined(values.userEmail) &&
                        Boolean(size(values.userEmail)))) &&
                      !values.userEmail.includes(rakuteDomainName) && (
                        <FormItem
                          label={f({
                            id: "app.user.modal.password"
                          })}
                          colon={false}
                          key="form-password"
                        >
                          <Input.Password
                            size="large"
                            key="password"
                            placeholder={f({
                              id: "app.user.modal.enter_password"
                            })}
                            type="password"
                            className={
                              touched.userPassword &&
                              errors.userPassword &&
                              "input-error"
                            }
                            visibilityToggle
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getFieldProps("userPassword")}
                            autoComplete="new-password"
                          />
                          <ErrorMessage
                            name="userPassword"
                            render={msg => (
                              <div className="error-text">
                                {f({
                                  id: msg
                                })}
                              </div>
                            )}
                          />
                        </FormItem>
                      )}
                  </Col>
                  <Col xs={24} lg={12}>
                    {(!isUndefined(queryParameters.email) ||
                      (touched.userEmail &&
                        isUndefined(errors.userEmail) &&
                        !isUndefined(values.userEmail) &&
                        Boolean(size(values.userEmail)))) &&
                      !values.userEmail.includes(rakuteDomainName) && (
                        <FormItem
                          colon={false}
                          key="form-confirm-password"
                          label="1"
                          className="hide"
                        >
                          <Input.Password
                            size="large"
                            key="confirm_password"
                            placeholder={f({
                              id: "app.user.modal.enter_confirm_password"
                            })}
                            type="password"
                            className={
                              touched.userConfirmPassword &&
                              errors.userConfirmPassword &&
                              "input-error"
                            }
                            visibilityToggle
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...getFieldProps("userConfirmPassword")}
                          />
                          <ErrorMessage
                            name="userConfirmPassword"
                            render={msg => (
                              <div className="error-text">
                                {f({
                                  id: msg
                                })}
                              </div>
                            )}
                          />
                        </FormItem>
                      )}
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div
            style={{
              padding: "20px 42px",
              background: "#F7F7F7"
            }}
            key="form-buttons"
          >
            <Row flexDirection="ltr" gutter={{ xs: 0, sm: 0, md: 16, lg: 16 }}>
              <Col xs={24} md={12} lg={12}>
                <FormItem
                  labelCol={{ xs: 0, sm: 0, md: 11 }}
                  label={<div style={{ opacity: 0 }}></div>}
                  colon={false}
                  style={{ marginBottom: "0 !important" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    data-testid={submitButtonId}
                    onClick={() => handleRegister(values)}
                    style={{ width: "100%" }}
                    disabled={
                      (!isUndefined(queryParameters.email) && !isEmailValid) ||
                      isUndefined(queryParameters.email) ||
                      !size(values.userFirstName) ||
                      !size(values.userLastName) ||
                      (!isUndefined(queryParameters.email) &&
                        queryParameters.email.includes(rakuteDomainName) &&
                        !size(rakutenName.firstName) &&
                        (isEmpty(touched) || !isEmpty(errors))) ||
                      !isEmpty(errors)
                    }
                  >
                    {f({ id: "app.user.modal.register" })}
                  </Button>
                </FormItem>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <FormItem style={{ marginBottom: "0 !important" }}>
                  <Button
                    size="large"
                    type="primary"
                    ghost
                    style={{ width: "100%" }}
                    onClick={handleCancel}
                    data-testid={cancelButtonId}
                  >
                    {f({ id: "app.user.modal.cancel" })}
                  </Button>
                </FormItem>
              </Col>
            </Row>
            {/* <FormItem>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} lg={6}>
                  
                </Col>
                <Col xs={24} sm={24} lg={6}>
                  
                </Col>
              </Row>
            </FormItem> */}
          </div>
        </Form>
      </div>
    );
  };
  FormContainer.propTypes = {
    props: PropTypes.object.isRequired,
    getFieldProps: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired
  };
  return (
    <Row
      type="flex"
      justify="space-around"
      gutter={32}
      style={{ marginTop: 32 }}
    >
      <Col span={24}>
        <ContentCard>
          <Row
            type="flex"
            justify="space-between"
            style={{
              margin: "0 0"
            }}
          >
            <Col
              style={{
                flex: 1
              }}
            >
              <ContentWrapper>
                <Wrapper>
                  <Row type="flex">
                    <Col span={24}>
                      {loading ? (
                        <Row>
                          <Loader />
                        </Row>
                      ) : (
                        <Formik
                          initialValues={{
                            userFirstName: size(rakutenName.firstName)
                              ? rakutenName.firstName
                              : user.firstName,
                            userLastName: size(rakutenName.lastName)
                              ? rakutenName.lastName
                              : user.lastName,
                            userEmail: isUndefined(queryParameters.email)
                              ? ""
                              : queryParameters.email,
                            userPassword: user.userPassword,
                            userConfirmPassword: user.userConfirmPassword
                          }}
                          validationSchema={CreateUser}
                          isValidating
                        >
                          {props => <FormContainer props={props} />}
                        </Formik>
                      )}
                    </Col>
                  </Row>
                </Wrapper>
              </ContentWrapper>
              <GlobalStyle />
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  );
}
UserRegistrationForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  rakutenDetails: PropTypes.object.isRequired
};
const withConnect = connect(
  state => ({
    messages: state.global.messages,
    rakutenDetails: state.global.rakutenDetails
  }),
  null
);

export default compose(
  withConnect,
  memo
)(UserRegistrationForm);
