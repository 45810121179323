import _, { find, isUndefined, get } from "lodash";
import commonHttp from "./utils/commonHttp";
import aaHttp from "./utils/aaHttp";
import { NO_PARTNER } from "../constants";
/**
 *
 * @param {string} tableName -  To fetch users from the table
 */
async function getUsers(tableName) {
  try {
    const result = await commonHttp.get(`lookup/${tableName}`);
    const { status, payload } = result.data;
    return {
      success: status === "SUCCESS",
      data: payload
    };
  } catch (e) {
    return {
      success: false,
      data: []
    };
  }
}

/**
 *
 * @param {string} email -  To fetch users from the table with thte same email id
 */
async function getUserEmailId(email) {
  const requestPayload = {
    email
  };
  try {
    const result = await aaHttp.post(`users/search`, requestPayload);
    const { status, payload } = result.data;
    return {
      success: status === "SUCCESS",
      data: isUndefined(find(payload.users, { email }))
        ? []
        : [find(payload.users, { email })]
    };
  } catch (e) {
    console.log(e);
    return {
      success: false,
      data: []
    };
  }
}
/**
 *
 * @param {string} email -  To register users with the provided details
 */
async function registerNewUser({
  first_name,
  last_name,
  email,
  password,
  metadata
}) {
  const requestPayload = {
    email,
    first_name,
    last_name,
    password
  };
  try {
    const result = await aaHttp.post("user/register", requestPayload);
    const { status, payload, error } = result.data;
    if (status === "SUCCESS" && isUndefined(error)) {
      return {
        success: true,
        data: payload
      };
    }
    return {
      success: false,
      data: error.message || "Something went wrong"
    };
  } catch (e) {
    return {
      success: false,
      data: []
    };
  }
}

async function getRakutenDetails(userEmail) {
  try {
    const result = await aaHttp.get(`/user/${userEmail}`);
    const { status, payload, error } = result.data;
    if (status === "SUCCESS" && isUndefined(error)) {
      return {
        success: true,
        data: payload
      };
    }
    return {
      success: false,
      data: error.message || "Something went wrong"
    };
  } catch (e) {
    return {
      success: false,
      data: []
    };
  }
}

function formatNames(appName) {
  appName = _.replace(appName.toLowerCase(), /\s/g, "");
  appName = _.replace(appName.toLowerCase(), /-/g, "");
  appName = _.replace(appName.toLowerCase(), /_/g, "");
  return appName;
}

export const getPartners = async appName => {
  try {
    const {
      data: { payload, status, error }
    } = await aaHttp.get(`applicationPartnerRoles`);
    if (!status) {
      return {
        success: false,
        data: [],
        message: get(error, "message")
      };
    }
    if (status) {
      let { partnerRoles = [], applicationName } =
        _.find(
          payload.applicationPartnerRoleBeans,
          r => formatNames(r.applicationName) === formatNames(appName)
        ) || {};
      partnerRoles = _.filter(
        partnerRoles,
        ({ partnerId = "  " }) => !_.isEmpty(partnerId)
      );
      partnerRoles = _.map(partnerRoles, p => ({
        label: p.partnerName,
        value: p.partnerId
      }));
      return {
        success: true,
        data: { applicationName, partnerRoles }
      };
    }
  } catch (e) {
    console.log(e);
    return {
      success: false,
      data: [],
      message: get(e, "message", "Something went wrong")
    };
  }
};

export const requestPartners = async params => {
  try {
    const {
      data: { status, payload }
    } = await aaHttp.post(`authorization/requestAccess`, params);
    if (status === "ERROR") {
      return {
        success: false,
        message: get(payload, "message", "Something went wrong")
      };
    }
    if (status === "SUCCESS") {
      return {
        success: true,
        message: get(payload, "message", "Successfully created the request")
      };
    }
  } catch (e) {
    console.log(e);
    return {
      success: false,
      message: get(e, "data.error.message", "Something went wrong")
    };
  }
};

export const getApplicationPartners = async params => {
  try {
    const response = await aaHttp.get(
      `application/${params.id}/partner_requests`
    );
    return {
      success: true,
      data: {
        partners: _.chain(response)
          .get("data.payload.partners")
          .map(partner => {
            const status = _.get(partner, "status").toLowerCase();
            const info = _.includes(["rejected", "pending"], status)
              ? _.startCase(status)
              : null;
            const disabled = !_.isNull(info);
            return {
              label: _.get(partner, "partner_name"),
              value: _.get(partner, "partner_id") || NO_PARTNER,
              disabled,
              info
            };
          })
          .value()
      }
    };
  } catch (e) {
    return { success: false, e };
  }
};

export const getApplicationPartnersList = async params => {
  try {
    const response = await aaHttp.get(
      `application/${params.id}/partner/status`
    );
    const partners = _.chain(response)
      .get("data.payload.partners")
      .map(partner => {
        const status = _.chain(partner)
          .get("status")
          .value();
        const info = _.chain(status)
          .toLower()
          .startCase()
          .value();
        if (["APPROVED"].includes(status)) {
          return null;
        }
        const disabled = ["PENDING"].includes(status);
        return {
          id: _.uniqueId(),
          label: _.get(partner, "partner_name", "No Partner"),
          value: _.get(partner, "partner_id"),
          disabled,
          info
        };
      })
      .compact()
      .value();

    return {
      success: true,
      data: {
        application: {
          application_id: _.get(response, "data.payload.application_id"),
          application_name: _.get(response, "data.payload.application_name")
        },
        partners
      }
    };
  } catch (e) {
    return { success: false, error: e.message };
  }
};

export default {
  getUsers,
  getUserEmailId,
  registerNewUser,
  getRakutenDetails,
  getPartners,
  requestPartners
};
