import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import _ from "lodash";
import ContentCard from "../../../components/ContentCard";
import PasswordResetForm from "../../../components/PasswordResetForm";

const Heading = styled.h3`
  font-size: 1.25rem;
  color: #111;
  font-weight: 300;
  padding: 1rem 1rem 3rem 1rem;
  margin: 0 !important;
  width: 100%;
`;

const ProfileImagePlaceholder = styled.div`
  background: #2eb1e2;
  color: rgb(255, 255, 255);
  border-radius: 100px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 1.15rem;
  font-weight: 200;
`;

const FormGroup = styled.div`
  padding: 1rem;
  width: 50%;
  span {
    color: #808080;
    font-weight: 300;
    padding: 0rem 0.5rem;
  }
  div {
    padding: 0.5rem;
    margin: 0.5rem 0;
    background: #f0f0f0;
    border-radius: 0.15rem;
    color: #111;
    display: block;
    overflow-wrap: break-word;
  }
`;
function nameToInitials(fullName) {
  const namesArray = fullName.split(" ");
  if (namesArray.length === 1) {
    return `${namesArray[0].charAt(0)}`;
  }
  return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(
    0
  )}`;
}

function ProfileSettings() {
  const user = JSON.parse(localStorage.getItem("user"));
  const email = _.get(user, "email");
  const name = _.get(user, "name", "N A");
  const initials = nameToInitials(name);
  return (
    <Row
      type="flex"
      justify="space-around"
      gutter={32}
      style={{ marginTop: 32 }}
    >
      <Col span={24}>
        <ContentCard>
          <Row
            type="flex"
            justify="space-between"
            style={{
              margin: "12px 16px"
            }}
          >
            <Col
              style={{
                flex: 1
              }}
            >
              <Row>
                <Heading>Profile</Heading>
                <div
                  style={{
                    display: "flex",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      margin: "0 1rem",
                      textAlign: "center"
                    }}
                  >
                    <ProfileImagePlaceholder>
                      {initials}
                    </ProfileImagePlaceholder>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%"
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <FormGroup>
                        <span>First Name</span>
                        <div>{_.first(name.split(" "))}</div>
                      </FormGroup>
                      <FormGroup>
                        <span>Last Name</span>
                        <div>{_.last(name.split(" "))}</div>
                      </FormGroup>
                    </div>
                    <FormGroup
                      style={{
                        width: "50%"
                      }}
                    >
                      <span>Email</span>
                      <div>{email}</div>
                    </FormGroup>
                  </div>
                </div>
              </Row>
            </Col>
            {!email.endsWith("@rakuten.com") ? (
              <Col span={8} style={{ borderLeft: "solid 1px #f1f1f1" }}>
                <Row>
                  <PasswordResetForm />
                </Row>
              </Col>
            ) : null}
          </Row>
        </ContentCard>
      </Col>
    </Row>
  );
}

export default ProfileSettings;
