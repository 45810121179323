import React from "react";
import { notification } from "antd";
import {
  CheckOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

const configuration = {
  error: {
    background: "#FFEFF0",
    borderColor: "#F30000",
    color: "#DF0101",
    icon: <WarningOutlined style={{ color: "#DF0101" }} />
  },
  info: {
    background: "#E8F7FF",
    borderColor: "#006497",
    color: "#006497",
    icon: <InfoCircleOutlined style={{ color: "#006497" }} />
  },
  warning: {
    background: "#faad1426",
    borderColor: "#faad14",
    color: "#faad14",
    icon: <ExclamationCircleOutlined style={{ color: "#faad14" }} />
  },
  success: {
    background: "#EBF7EC",
    borderColor: "#93C594",
    color: "#047205",
    icon: <CheckOutlined style={{ color: "#047205" }} />
  }
};

export default function({ type = "error", msg = "", description = "" }) {
  const config = configuration[type];
  notification[type]({
    style: {
      background: config.background,
      border: `1px solid ${config.borderColor}`,
      borderRadius: "12px",
      color: config.borderColor
    },
    icon: config.icon,
    message: <span style={{ color: config.color }}>{msg}</span>,
    description
  });
}
