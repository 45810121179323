import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ContentCard from "../../../components/ContentCard";
import ComponentContainer from "../../../components/ComponentContainer";
import NotificationSettings from "./NotificationSettings";
import ProfileSettings from "./ProfileSettings";

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  a {
    color: #707070;
    height: 60px;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    font-weight: 300;
    border-bottom: solid 4px #fff;

    &.active {
      border-color: #1890ff;
      font-weight: 600;
      color: #000000;
    }
    &:hover {
      color: #000000;
    }
  }
`;

const Wrapper = styled.div`
  width: 1121px;
  margin: auto;
  @media only screen and (max-width: 1120px) {
    overflow-x: auto;
  }
  @media only screen and (min-width: 1431px) {
    width: 1280px;
  }
`;

function SettingsPage({ match }) {
  const { path, url } = match;
  return (
    <ComponentContainer>
      <Wrapper>
        <div>
          <a
            href="#/"
            style={{
              color: "#606060",
              fontSize: 20,
              padding: "2rem 0 2rem 0",
              display: "inline-block"
            }}
          >
            <ArrowLeftOutlined /> &nbsp; Back to Apps
          </a>
        </div>
        <ContentCard title="Settings" ribbon>
          <Navigation>
            <NavLink exact to={`${url}`} activeClassName="active">
              Notification Settings
            </NavLink>
            <NavLink exact to={`${url}/profile`} activeClassName="active">
              Profile Settings
            </NavLink>
          </Navigation>
        </ContentCard>
        <Switch>
          <Route exact path={`${path}/profile`} component={ProfileSettings} />
          <Route exact path={path} component={NotificationSettings} />
        </Switch>
      </Wrapper>
    </ComponentContainer>
  );
}

SettingsPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(SettingsPage);
