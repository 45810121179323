import React from "react";
import PropTypes from "prop-types";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Brand = ({ id, showIcon = false }) => (
  <span>
    {showIcon ? (
      <img
        style={{ width: 28, margin: "0 8px" }}
        src={`https://tools-cdn.rcprakuten.com/public/assets/images/${id}-logo.svg`}
        alt={id}
      />
    ) : null}
    {showIcon ? (
      <b
        style={{
          fontSize: 18,
          fontWeight: 700
        }}
      >
        {capitalize(id)}
      </b>
    ) : (
      id
    )}
  </span>
);

Brand.propTypes = {
  id: PropTypes.string
};

export default Brand;
