import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useToggle, useClickAway } from "react-use";
import { BsThreeDots } from "react-icons/bs";
import { Button } from "antd";
import _ from "lodash";
import { breakpoint } from "./Page";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1136px;
  margin-bottom: 2rem;
`;

export const Title = styled.div`
  display: block;
  align-items: center;
  text-align: center;
  margin: 3rem 0 2rem;
  line-height: 1.5rem;
  ${breakpoint("md", "xl")`
     margin: 1rem 0 1.5rem; 
     font-size: 1.3rem;
     line-height: 1.6rem;
  `}
  h1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0rem;
    ${breakpoint("xxs", "md")`
      font-size: 1.2rem;    
    `}
  }
  div {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #bd0000 0% 0% no-repeat padding-box;
    text-align: center;
  }
`;

const CardMoreActionButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  border-radius: 4px;
  width: 47px;
  height: 28px;
  font-size: 24px;
  position: absolute;
  right: 0px;
  top: 12px;
  background: none;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #fafafa61;
    border-radius: 4px 0 0 4px;
  }
  &.active {
    padding-right: 4px;
    background: #e2e2e2;
    border-radius: 4px 0 0 4px;
    box-shadow: inset 0px 0px 8px #00000058;
  }
`;

const CardMoreActionButtonPopover = styled.div`
  position: absolute;
  right: 12px;
  left: 12px;
  background: #fff;
  top: 48px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  z-index: 1;
  .popover-row {
    display: flex;
    align-items: center;
    justify-content: start;
    min-height: 48px;
    padding: 0 15px;
    border-bottom: 1px solid #7070701a;
    font-size: 13px;
    color: #4a4a4a;
    background: #fff;
    text-align: left;
    .icon {
      width: 26px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .bold {
      font-weight: 700;
    }
    .request-link {
      color: #134ff3;
    }
  }
`;

const CardMoreActionsButton = ({
  appId,
  appName,
  displayName,
  accessRequest,
  onRequestAccess
}) => {
  const ref = useRef(null);
  const count = _.get(accessRequest, "count", {
    approved: 0,
    pending: 0
  });
  const handleError = () => {};
  const [on, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });
  const handleClick = () => {
    toggle(true);
  };
  const handleRequestAccess = () => {
    toggle(false);
    onRequestAccess({ appId, appName, displayName });
  };
  return (
    <div ref={ref}>
      <CardMoreActionButtonWrapper
        onClick={handleClick}
        className={on ? "active" : ""}
      >
        <BsThreeDots />
      </CardMoreActionButtonWrapper>
      {on ? (
        <CardMoreActionButtonPopover>
          <div className="popover-row">
            <div className="icon">
              <img
                src={`https://tools-cdn.rcprakuten.com/public/assets/logos/${appId}.svg`}
                alt={displayName}
                style={{ width: "100%" }}
                onError={handleError}
              />
            </div>
            <div className="bold truncate">{displayName}</div>
          </div>
          <div className="popover-row">
            <div className="icon">
              <img
                src="https://tools-cdn.rcprakuten.com/public/assets/images/icons/approval-check.svg"
                alt="Approved Partners Icon"
                width="16px"
                onError={handleError}
              />
            </div>
            <div>
              Approved Partners <span className="bold">({count.approved})</span>
            </div>
          </div>
          <div className="popover-row">
            <div className="icon">
              <img
                src="https://tools-cdn.rcprakuten.com/public/assets/images/icons/wall-clock.svg"
                alt="Pending Approvals Icon"
                width="16px"
                onError={handleError}
              />
            </div>
            <div>
              Pending Approvals <span className="bold">({count.pending})</span>
            </div>
          </div>
          <div className="popover-row">
            <div className="icon">
              <img
                src="https://tools-cdn.rcprakuten.com/public/assets/images/lock.svg"
                alt="Request for new access Icon"
                width="12px"
                onError={handleError}
              />
            </div>
            <div>
              <Button
                type="link"
                className="request-link"
                onClick={handleRequestAccess}
                style={{
                  padding: "0",
                  width: "100%"
                }}
              >
                Request for new access
              </Button>
            </div>
          </div>
        </CardMoreActionButtonPopover>
      ) : null}
    </div>
  );
};

CardMoreActionsButton.propTypes = {
  appId: PropTypes.string,
  appName: PropTypes.string,
  displayName: PropTypes.string,
  accessRequest: PropTypes.object,
  onRequestAccess: PropTypes.func
};

export default CardMoreActionsButton;
