const addLocaleData = require("react-intl").addLocaleData; //eslint-disable-line
// const enLocaleData = require("react-intl/locale-data/en"); //eslint-disable-line
// const jpLocaleData = require("react-intl/locale-data/jp"); //eslint-disable-line
// const deLocaleData = require("react-intl/locale-data/de"); //eslint-disable-line

const enTranslationMessages = require("./translations/en.json");
const jpTranslationMessages = require("./translations/jp.json");
const deTranslationMessages = require("./translations/de.json");

// addLocaleData(enLocaleData);
// addLocaleData(jpLocaleData);
// addLocaleData(deLocaleData);

export const DEFAULT_LOCALE = "en";

export const appLocales = ["en", "de"];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  jp: formatTranslationMessages("jp", jpTranslationMessages),
  de: formatTranslationMessages("de", deTranslationMessages)
};
