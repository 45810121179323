import axios from "axios";
import { clearUser } from "../../app-shell/src/utils";

const http = axios.create({
  baseURL: "/service/rcp-tools/api"
});

http.interceptors.request.use(
  config => {
    try {
      const { token } = JSON.parse(localStorage.getItem("user")) || {};
      config.headers.rcpauth = token || "";
      return config;
    } catch (error) {
      return config;
    }
  },
  err => {
    console.log("error");
  }
);

http.interceptors.response.use(
  response => {
    return response;
  },
  err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      clearUser();
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
