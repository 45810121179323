import styled from "styled-components";

const SelectOption = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  padding: 0 1.5rem;
  color: #878787;
  min-height: 32px;
  line-height: 32px;
  &:hover {
    cursor: pointer;
  }
  &.active {
    color: #000000;
    .icon {
      color: #000000;
    }
  }
  .title {
    flex: 1;
    line-height: normal;
    word-break: break-word;
    padding: 8px 0;
    margin-left: 1rem;
    color: #000000;
  }
  .icon {
    color: #acacac;
  }
  .info {
    font-size: 12px;
    align-self: center;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export default SelectOption;
