import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ContentCard from "../../../components/ContentCard";
import ComponentContainer from "../../../components/ComponentContainer";
import UserRegistrationForm from "./UserRegistrationForm";

function RegisterPage({ match }) {
  const { path, url } = match;
  return (
    <div style={{ marginTop: "2rem" }}>
      <ComponentContainer>
        <ContentCard title="Add User" ribbon></ContentCard>
        <UserRegistrationForm />
      </ComponentContainer>
    </div>
  );
}

RegisterPage.propTypes = {
  match: PropTypes.object
};

export default withRouter(RegisterPage);
