/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Button, Tag } from "antd";
import {
  CloseCircleFilled,
  CheckOutlined,
  ClockCircleOutlined,
  StopOutlined
} from "@ant-design/icons";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useGetSetState } from "react-use";
import { useQueryParams, StringParam } from "use-query-params";
import MultiSelectMenu from "../../../common/MultiSelectMenu";
import {
  getApplicationPartnersList,
  requestPartners
} from "../../../api/admin";
import { notification, Spinner } from "../../../common";
import { fetchApps } from "../../../actions";
import { NO_PARTNER } from "../../../constants";

const { TextArea } = Input;
const RequestAccessBtn = styled(Button)`
  color: #ffff !important;
  background: #134ff3 !important;
  font-size: 1rem;
  line-height: 1.25rem;
  min-height: 2.5rem;
  margin: 1rem 0;
  padding: 10px;
  border-radius: 5px !important;
`;
const InfoBar = styled.div`
  min-height: 3.375rem;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 10px;
  span {
    margin-left: 0.8rem;
    color: #000000;
    line-height: 1.375rem;
    font-size: 0.9rem;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    min-height: 24.563rem;
  }
  .content {
    padding: 0 1.375rem 0 1.375rem;
    margin-top: 0.75rem;
    textarea {
      margin-top: 0.75rem;
    }
    .p {
      margin-top: 0.75rem;
      color: #707070;
      font-size: 0.813rem;
      line-height: 1rem;
      padding: 0 0.5rem;
    }
  }
`;

const RequestTextArea = styled(TextArea)``;

// eslint-disable-next-line react/prop-types
const PartnerSelectOption = ({ label, info }) => {
  let Icon = CheckOutlined;
  let color = "white";
  if (info === "Pending") {
    Icon = ClockCircleOutlined;
    color = "orange";
  }
  if (info === "Rejected") {
    Icon = StopOutlined;
    color = "red";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 12px 8px 12px",
        marginBottom: "-1px",
        borderBottom: "1px solid #d9d9d9"
      }}
    >
      <div>{label}</div>
      <Tag
        color={color}
        icon={<Icon />}
        style={{
          fontSize: "12px",
          marginRight: 0,
          marginLeft: "auto",
          borderRadius: "2px",
          minWidth: "84px"
        }}
      >
        {info}
      </Tag>
    </div>
  );
};

const ModalStatus = {
  DONE: "DONE",
  LOADING: "LOADING",
  ERROR: "ERROR"
};

const initialState = {
  app: null,
  partners: null,
  status: ModalStatus.LOADING,
  selectedPartners: [],
  comment: ""
};
function RequestAccessModal({ visible, onClose }) {
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const [get, setState] = useGetSetState(initialState);
  const [query, setQuery] = useQueryParams({
    externalId: StringParam,
    appId: StringParam,
    appName: StringParam,
    displayName: StringParam
  });

  useEffect(() => {
    if (visible) {
      setState({
        ...get(),
        status: ModalStatus.LOADING
      });
      getApplicationPartnersList({ id: query.externalId })
        .then(response => {
          if (!response.success) {
            notification({ type: "error", msg: _.get(response, "message") });
            return;
          }
          setState({
            ...get(),
            app: _.get(response, "data.application"),
            partners: _.get(response, "data.partners", [])
          });
        })
        .finally(() => {
          setState({
            ...get(),
            status: ModalStatus.DONE
          });
        });
    }
    if (!visible) {
      setState(initialState);
    }
  }, [visible]);

  const onPartnerSelection = value => {
    const newSelectedPartners = _.includes(get().selectedPartners, value)
      ? _.difference(get().selectedPartners, [value])
      : _.union(get().selectedPartners, [value]);
    setState({
      ...get(),
      selectedPartners: newSelectedPartners
    });
  };

  const submitRequest = () => {
    setState({
      ...get(),
      status: ModalStatus.LOADING
    });
    const params = {
      partnerIds: _.map(get().selectedPartners, partnerId =>
        partnerId === NO_PARTNER ? "" : partnerId
      ),
      applicationId: get().app.application_id,
      applicationName: get().app.application_name,
      comment: _.trim(get().comment)
    };
    requestPartners(params)
      .then(response => {
        if (!response.success) {
          notification({ type: "error", msg: _.get(response, "message") });
          return;
        }
        notification({ type: "success", msg: _.get(response, "message") });
        setQuery({ appId: undefined, appName: undefined, externalId: null });
        dispatch(fetchApps());
      })
      .catch(e => {
        console.log(e);
        notification({ type: "error", msg: "Something went wrong" });
      })
      .finally(() => {
        setState({
          ...get(),
          status: ModalStatus.DONE
        });
      });
  };

  const handleError = () => {
    imageRef.current.width = 32;
    imageRef.current.height = 32;
    imageRef.current.src = `https://tools-cdn.rcprakuten.com/public/assets/images/defaultImage.svg`;
  };

  const { partners = [], selectedPartners = [], comment, status } = get();
  const pendingPartnerRequests = _.filter(partners, { info: "Pending" });
  const loader = status === ModalStatus.LOADING;
  return (
    <div style={{ marginTop: "2rem" }}>
      <StyledModal
        width={400}
        title={
          <span styled={{ fontSize: "1rem", lineHeight: "1.375rem" }}>
            <b>Request access to</b>
          </span>
        }
        visible={visible}
        closeIcon={
          <CloseCircleFilled
            style={{ fontSize: "1.2rem", color: "#9C9C9C" }}
            onClick={onClose}
          />
        }
        footer={null}
      >
        {loader ? <Spinner /> : null}
        {!loader ? (
          <div>
            <InfoBar>
              <span>
                <img
                  ref={imageRef}
                  alt="logo"
                  src={`https://tools-cdn.rcprakuten.com/public/assets/logos/${query.appId}.svg`}
                  height="32"
                  width="31"
                  onError={handleError}
                />
              </span>
              <span>{query.displayName}</span>
            </InfoBar>
            <div className="content">
              <MultiSelectMenu
                placeholder="Select Partner/s"
                values={selectedPartners}
                options={_.filter(partners, { disabled: false })}
                menuOptions={{ width: 356, height: 250 }}
                onSelect={onPartnerSelection}
                customStyle={{ width: "100%", height: "40px" }}
              />
              {!_.isEmpty(pendingPartnerRequests) ? (
                <div
                  style={{
                    marginTop: "12px",
                    border: "1px solid #d9d9d9"
                  }}
                >
                  {pendingPartnerRequests.map(option => (
                    <PartnerSelectOption {...option} />
                  ))}
                </div>
              ) : null}
              <RequestTextArea
                required
                onChange={({ target }) =>
                  setState({ ...get(), comment: target.value })
                }
                placeholder="Please help us understand your requirements *"
                autoSize={{ minRows: 5, maxRows: 5 }}
                maxLength="1024"
                value={comment}
              />
              <div style={{ textAlign: "center" }}>
                <RequestAccessBtn
                  disabled={
                    _.isEmpty(selectedPartners) || _.isEmpty(_.trim(comment))
                  }
                  onClick={submitRequest}
                >
                  Request Access
                </RequestAccessBtn>
                <p>
                  The request will be validated by an Administrator. You will
                  get a notification after your request has been approved.
                </p>
                <br />
              </div>
            </div>
          </div>
        ) : null}
      </StyledModal>
    </div>
  );
}

RequestAccessModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.string
};

export default RequestAccessModal;
