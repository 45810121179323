import _, { isUndefined, isEmpty, lowerCase } from "lodash";
import moment from "moment";
export const rakuteDomainName = "@rakuten.com";
/**
 *
 * @param {string} url - To get query parameters from the passed url
 * @returns object with parameters
 */
export const getQueryParams = url => {
  const queryParams = {};
  const queryStrings = url
    .split("?")
    .slice(1)
    .join("?");
  if (!isUndefined(queryStrings)) {
    const params = queryStrings.split("&");

    for (let i = 0; i < params.length; i++) {
      const pair = params[i].split("=");
      queryParams[pair[0]] = decodeURI(pair.slice(1).join("="));
    }
  }
  return queryParams;
};
/**
 *
 * @returns sorted alphabets
 */
export const alphabetCompare = (a, b) =>
  _.toLowerCase(a).localeCompare(lowerCase(b));
/**
 *
 * @returns sorted date
 */
export const dateCompare = (a, b) =>
  new Date(a).getTime() - new Date(b).getTime();

export const dateFormat = date => {
  if (isEmpty(date)) {
    return date;
  }
  return moment(date).format("DD MMM, YYYY");
};
