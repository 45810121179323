import React, { useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import Cookies from "js-cookie";
import SecurityLock from "../../../assets/img/security.svg";
import ComponentContainer from "../../../components/ComponentContainer";
import api from "../../../api";

const Logout = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 0.25rem;
  margin: 3.6rem auto 0 auto;
  font-size: 1rem;
  width: 560px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  div {
    display: flex;
    justify-content: center;
  }
  .image-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: -26px;
    img {
    }
  }
  .heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 41px;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 37px;
    margin-bottom: 24px;
  }
  .loading-text {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0s;
    animation: dot 1.3s infinite;
    animation-delay: 0s;
  }

  .two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
  }

  .three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
  }

  @-webkit-keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

function LogoutPage() {
  const user = JSON.parse(localStorage.getItem("user"));
  const name = _.get(user, "first_name", "");
  const logout = async () => {
    const { success } = await api.logOut();
    if (success) {
      localStorage.setItem("logged_event", "logout");
      localStorage.removeItem("user");
      Cookies.remove("_user_data");
      Cookies.remove("RCP_TOOLS_TOKEN");
      window.location.href = "/rcp-tools/#/";
      window.location.reload();
    }
  };
  useEffect(() => {
    logout();
  }, []);
  return (
    <ComponentContainer>
      <Logout>
        <div className="image-wrapper">
          <img src={SecurityLock} alt="lock" />
        </div>
        <div className="heading">{name}</div>
        <div className="loading-text">
          You are being logged out<span className="one">.</span>
          <span className="two">.</span>
          <span className="three">.</span>
        </div>
      </Logout>
    </ComponentContainer>
  );
}

export default LogoutPage;
