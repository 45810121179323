import React from "react";
import ReactDOM from "react-dom";
import "intl";
import { Provider } from "react-redux";
import { Router, Route } from "react-router";
import { QueryParamProvider } from "use-query-params";
import RUI from "@rcp-tools/ui-kit";
import configureStore from "./configureStore";
import LanguageProvider from "./containers/LanguageProvider";
import history from "./utils/history";
import { translationMessages } from "./i18n";
import App from "./containers/App";
import "antd/dist/antd.css";
import "intl/locale-data/jsonp/en";

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("app");

const render = messages => {
  const onSuccess = () => {};
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <RUI.Shell
              logo={
                <img
                  width="32"
                  src="https://tools-cdn.rcprakuten.com/public/assets/logos/rcp-tools.svg"
                  alt="logo"
                />
              }
              title="RCP Console"
              supportLink="https://confluence.rakuten-it.com/confluence/display/RCP/5.+RCPC+2.0+%3A+User+Manual"
              onSuccess={onSuccess}
            >
              <App />
            </RUI.Shell>
          </QueryParamProvider>
        </Router>
      </LanguageProvider>
    </Provider>,

    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept(["./i18n", "./containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve();
//     //  resolve(import("intl"));
//   })
//     .then(() =>
//       Promise.all([
//         import("intl/locale-data/jsonp/en.js"),
//         import("intl/locale-data/jsonp/de.js")
//       ])
//     ) // eslint-disable-line prettier/prettier
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     });
// } else {

// }
render(translationMessages);

// Logout on multiple tabs
const storageChange = ({ key, newValue }) => {
  if (key === "logged_event" && newValue === "logout") {
    window.location.reload();
  }
};
window.addEventListener("storage", storageChange, false);
