import React from "react";
import styled from "styled-components";
import SecurityLock from "../../../assets/img/security.svg";

const NoResults = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 0.25rem;
  margin: auto;
  font-size: 1rem;
  width: 560px;
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  div {
    display: flex;
    justify-content: center;
  }
  .image-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: -26px;
    img {
    }
  }
  .heading {
    font-weight: bold;
    font-size: 32px;
    line-height: 41px;
    color: #ff0000;
    margin-top: 37px;
    margin-bottom: 24px;
  }
  .text {
    color: #000000;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .sub-text {
    color: #707070;
    font-size: 14px;
    margin-bottom: 28px;
    line-height: 17px;
  }
`;
export default () => (
  <NoResults>
    <div className="image-wrapper">
      <img src={SecurityLock} alt="lock" />
    </div>
    <div className="heading">OOPS!</div>
    <div className="text">
      It looks like you are not authorised to access RCP Tools.
    </div>
    <div className="sub-text">For assistance, please contact your manager.</div>
  </NoResults>
);
