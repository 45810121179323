import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    min-height: 100%; 
    font-family: RakutenSansUI, sans-serif !important;
    background-color: #f0f3f4 !important;
    padding-top: 64px;
  } 

  #app {
    min-height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-tooltip-arrow {
    display: none !important;
  }

  .error-text {
    line-height: 1;
    color: #f5222d;
  }

  .ant-input.input-error {
    border-color: #f5222d;
  }

  .ant-btn[disabled]{
    background-color: #afafaf !important;
    color: #FFFFFF !important;
    border-color: #afafaf !important;
  }
`;

export default GlobalStyle;
