export const APP_SHOW_ALERTS = "APP/APP_SHOW_ALERTS";
export const APP_CLEAR_ALERTS = "APP/APP_SHOW_ALERTS";
export const APP_FETCH_OPTIONS_ERROR = "APP/APP_FETCH_OPTIONS_ERROR";
export const APP_SET_LOADER = "APP/APP_SET_LOADER";
export const APP_SET_CONFIRMATION_MODAL = "APP/APP_SET_CONFIRMATION_MODAL";

export const APP_DELETE_USER_SUCCESS = "APP/APP_DELETE_USER_SUCCESS";
export const APP_DELETE_USER_FAIL = "APP/APP_DELETE_USER_FAIL";

export const APP_CLEAR_MESSAGES = "APP/APP_CLEAR_MESSAGES";
export const APP_SUCCESS_MESSAGES = "APP/APP_SUCCESS_MESSAGES";
export const APP_ERROR_MESSAGES = "APP/APP_ERROR_MESSAGES";
export const APP_GET_RAKUTEN_USER_DETAILS = "APP/APP_GET_RAKUTEN_USER_DETAILS";
export const APP_FETCH_APPS = "APP/APP_FETCH_APPS";

export const NO_PARTNER = "nopartner";
