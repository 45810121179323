import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
const Wrapper = styled.div`
  margin: 15% auto;
  width: 200px;
  text-align: center;
`;
export default () => {
  return (
    <Wrapper>
      <Spin size="large" indicator={antIcon} />
    </Wrapper>
  );
};
