import axios from "axios";
import Cookies from "js-cookie";

export async function setSSOUserToLocalStorage() {
  const rcpToolsToken = Cookies.get("RCP_TOOLS_TOKEN");
  const ssoUserData = Cookies.get("_user_data");
  if (rcpToolsToken && ssoUserData) {
    try {
      const userData = JSON.parse(ssoUserData);
      const authorizationResponse = await axios.post(
        `/service/rcp-tools/aa/api/authorization`,
        {
          headers: {
            rcpauth: rcpToolsToken,
          },
        },
      );
      let permissions;
      if (authorizationResponse.data.status === "SUCCESS") {
        permissions = authorizationResponse.data.payload.permissions;
      } else {
        permissions = [];
      }
      const user = {
        id: userData.user_id,
        email: userData.email,
        name: `${userData.first_name} ${userData.last_name}`,
        permissions,
        token: rcpToolsToken,
      };
      setUser(user);
    } catch (error) {
      console.log("Unable user in local storage", error);
    }
  }
}

export async function getAuthToken(params) {
  let success = false;
  try {
    const response = await axios.post("/service/rcp-tools/aa/api/auth", params);
    success = response.data.status === "SUCCESS";
    const data = response.data.payload;
    const userData = {
      user_id: data.user_id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      application_names: data.application_names,
    };
    setCookie("RCP_TOOLS_TOKEN", data.cookie);
    setCookie("_user_data", JSON.stringify(userData));
    const userDetailsResponse = await axios.post(
      `/service/rcp-tools/aa/api/authorization`,
      {
        headers: {
          rcpauth: data.cookie,
        },
      },
    );
    let permissions = [];
    if (userDetailsResponse.data.status === "SUCCESS") {
      permissions = userDetailsResponse.data.payload.permissions;
    }
    return {
      success,
      data: Object.assign(
        {},
        {
          id: data.user_id,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          name: `${data.first_name} ${data.last_name}`,
          permissions,
          token: data.cookie,
        },
      ),
    };
  } catch (error) {
    const message =
      error.response.data.error.message || "Something went wrong.";

    return {
      success,
      message,
    };
  }
}

export function getSSOError() {
  return Cookies.get("_sso_error") || false;
}

export function clearSSOError() {
  Cookies.remove("_sso_error");
}

export function getUser() {
  try {
    return JSON.parse(localStorage.getItem("user"));
  } catch (error) {
    return false;
  }
}

export function setUser(user) {
  localStorage.setItem("logged_event", "login");
  localStorage.setItem("user", JSON.stringify(user));
}

export function clearUser() {
  localStorage.setItem("logged_event", "logout");
  localStorage.removeItem("user");
  Cookies.remove("_user_data");
  Cookies.remove("RCP_TOOLS_TOKEN");
}

export function getAppIdFromUrl(url) {
  let appId = "rcp-tools";
  let { pathname: path = "" } = new URL(url);
  path = path.replace(/^\/+|\/+$/g, "");
  path = path.split("/");
  if (path.length) {
    appId = path.length > 1 ? path[1] : path[0];
  }
  return appId;
}

function setCookie(cname, cvalue) {
  document.cookie = `${cname}=${cvalue};${86400000};path=/`;
}
